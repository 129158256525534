import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import jwtDecode from 'jwt-decode'

const Views = lazy(() =>
    import(
        /* webpackChunkName: "views" */
        './../views'
    )
)

const Login = lazy(() =>
    import(
        /* webpackChunkName: "login" */
        './../views/Login'
    )
)

const ModifyPassword = lazy(() =>
    import(
        /* webpackChunkName: "modifyPassword" */
        './../views/ModifyPassword'
    )
)

const Home = lazy(() =>
    import(
        /* webpackChunkName: "home" */
        './../views/Home'
    )
)


const SheetMusicStyles= lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.series" */
        '../views/SheetMusic/fenleis'
    )
)
const SheetMusicSeries = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.series" */
        './../views/SheetMusic/Series'
    )
)

const SheetMusicBooks = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.book" */
        './../views/SheetMusic/Books'
    )
)

const SheetMusicBooksDetails = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.book.details" */
        './../views/SheetMusic/Books/details'
    )
)



const SheetMusicCollection = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.book" */
        './../views/SheetMusic/Collection'
    )
)

const SheetMusicCollectionDetails = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.book.details" */
        './../views/SheetMusic/Collection/details'
    )
)


const SheetMusicSong = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.book" */
        './../views/SheetMusic/Song'
    )
)

const SheetMusicSongDetails = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.book.details" */
        './../views/SheetMusic/Song/details'
    )
)


const YuePuList = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.book" */
        './../views/SheetMusic/YuePu'
    )
)

const YuePuDetail = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.book.details" */
        './../views/SheetMusic/YuePu/details'
    )
)

const SheetMusicArtists = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.book" */
        './../views/SheetMusic/Artists'
    )
)

const SheetMusicArtistsDetails = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.book.details" */
        './../views/SheetMusic/Artists/details'
    )
)

const VideoDiff = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.videodiff" */
        './../views/SheetMusic/VideoDiff'
    )
)

const MusicEdit = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.musicedit" */
        './../views/SheetMusic/MusicEdit'
    )
)

const Download = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.download" */
        './../views/SheetMusic/Download'
    )
)

const MusicScore = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.download.musicscore" */
        './../views/SheetMusic/Download/musicScore'
    )
)

const UserManage = lazy(() =>
    import(
        /* webpackChunkName: "usermanage" */
        './../views/UserManage'
    )
)



const CourseSeries = lazy(() =>
    import(
        /* webpackChunkName: "usermanage" */
        './../views/Course/Series'
    )
)

const lessonManage = lazy(() =>
    import(
        /* webpackChunkName: "usermanage" */
        '../views/Course/Lesson'
    )
)

const courseLessonDetails = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.book.details" */
        './../views/Course/Lesson/details'
    )
)

const partManage = lazy(() =>
    import(
        /* webpackChunkName: "usermanage" */
        '../views/Course/Part'
    )
)

const coursePartDetails = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.book.details" */
        './../views/Course/Part/details'
    )
)


const pointManage = lazy(() =>
    import(
        /* webpackChunkName: "usermanage" */
        '../views/Course/Point'
    )
)

const coursePointDetails = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.book.details" */
        './../views/Course/Point/details'
    )
)

/*-------------视频管理---------*/

const VideoManage = lazy(() =>
    import(
        /* webpackChunkName: "usermanage" */
        './../views/Video/List'
    )
)

const VideoDetails = lazy(() =>
    import(
        /* webpackChunkName: "usermanage" */
        './../views/Video/List/details'
    )
)

/* ------- 语音转换工具 ----------*/
const AudioTool = lazy(() =>
    import(
        /* webpackChunkName: "usermanage" */
        './../views/Audio/Tool/details'
    )
)
const MemberTool = lazy(() =>
import(
    /* webpackChunkName: "usermanage" */
    './../views/member/Tool/details'
)
)
const MessageList = lazy(() =>
import(
    /* webpackChunkName: "usermanage" */
    './../views/push/list'
)
)
const MessagePush = lazy(() =>
import(
    /* webpackChunkName: "usermanage" */
    './../views/push/message'
)
)
const AudioManage = lazy(() =>
    import(
        /* webpackChunkName: "usermanage" */
        './../views/Audio/List'
    )
)

const AudioDetails = lazy(() =>
    import(
        /* webpackChunkName: "usermanage" */
        './../views/Audio/List/details'
    )
)
/*--------------------- 曲谱管理 ------------*/

const SheetMusic = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.book" */
        './../views/SheetMusic/Music'
    )
)

const SheetMusicWhole = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.book" */
        './../views/SheetMusic/MusicWhole'
    )
)

const SheetMusicDetails = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.book.details" */
        './../views/SheetMusic/Music/details'
    )
)
const SheetMusicWholeDetails = lazy(() =>
    import(
        /* webpackChunkName: "sheetmusic.book.details" */
        './../views/SheetMusic/MusicWhole/details'
    )
)

/*--------------------- 歌曲管理 ------------*/

const SongSeries = lazy(() =>
    import(
        /* webpackChunkName: "usermanage" */
        './../views/Song/Series'
    )
)



const SongGroup = lazy(() =>
    import(
        /* webpackChunkName: "usermanage" */
        './../views/Song/Group'
    )
)

const SongGroupDetails = lazy(() =>
    import(
        /* webpackChunkName: "usermanage" */
        './../views/Song/Group/details'
    )
)


const SongList = lazy(() =>
    import(
        /* webpackChunkName: "usermanage" */
        './../views/Song/Song'
    )
)

const SongDetail = lazy(() =>
    import(
        /* webpackChunkName: "usermanage" */
        './../views/Song/Song/details'
    )
)

const SongPointList = lazy(() =>
    import(
        /* webpackChunkName: "usermanage" */
        './../views/Song/Point'
    )
)

const SongPointDetail = lazy(() =>
    import(
        /* webpackChunkName: "usermanage" */
        './../views/Song/Point/details'
    )
)

const PreviewMusicScore = lazy(() =>
    import(
        /* webpackChunkName: "usermanage" */
        './../views/Preview/Music/details'
    )
)


function Suspenses(props) {
    return <Suspense fallback={<div style={{ padding: 16 }}>Loading...</div>}>{props.children}</Suspense>
}

function Routers() {
    
    const token = window.localStorage.getItem('token')
    // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJIVFBQIiwiVXNlck5hbWUiOiJhZG1pbiIsIlVzZXJJZCI6MSwiZXhwIjoxNjQ0NzE5NTk5LCJuYmYiOjE2NDIxMjc1OTl9.OBpu4CYGm_Jj2st7T-O7ikmMLh7AroXJfFl5PsSRiVo';
    let isLogin;
    // console.log('token====',token);
    // const userName = window.sessionStorage.getItem('username');
    // console.log('username====',userName);
    // 检查jwt是否过期
    if (token) {
        const decoded = jwtDecode(token)
        const { exp } = decoded || {}
        const now = Date.now()
        const exps = exp * 1000
        isLogin = now < exps
    }

    return (
        <Router>
            <Switch>
                <Suspenses>
                    <Route path="/login" component={Login} />
                    <Route path="/views" component={Views}>
                        <Views>
                            <Switch>
                                <Suspenses>
                                    <Route path="/views/home" component={Home}></Route>
                                    <Route path="/views/modifyPassword" component={ModifyPassword}></Route>
                                    <Route path="/views/sheetmusic/music_edit" component={MusicEdit}></Route>
                                    <Route path="/views/sheetmusic/fenleis" component={SheetMusicStyles}></Route>
                                    <Route path="/views/sheetmusic/series" component={SheetMusicSeries}></Route>
                                    <Route path="/views/sheetmusic/books" component={SheetMusicBooks}></Route>
                                    <Route path="/views/sheetmusic/books_details" component={SheetMusicBooksDetails}></Route>
                                    <Route path="/views/sheetmusic/collection" component={SheetMusicCollection}></Route>
                                    <Route path="/views/sheetmusic/collection_details" component={SheetMusicCollectionDetails}></Route>
                                    <Route path="/views/sheetmusic/songs" component={SheetMusicSong}></Route>
                                    <Route path="/views/sheetmusic/song_details" component={SheetMusicSongDetails}></Route>
                                    <Route path="/views/sheetmusic/artists" component={SheetMusicArtists}></Route>
                                    <Route path="/views/sheetmusic/artist_details" component={SheetMusicArtistsDetails}></Route>
                                    <Route path="/views/sheetmusic/yuepu" component={YuePuList}></Route>
                                    <Route path="/views/sheetmusic/yuepu_details" component={YuePuDetail}></Route>
                                    
                                    <Route path="/views/sheetmusic/music" component={SheetMusic}></Route>
                                    <Route path="/views/sheetmusic/music_details" component={SheetMusicDetails}></Route>
                                    <Route path="/views/sheetmusic/musicwhole" component={SheetMusicWhole}></Route>
                                    <Route path="/views/sheetmusic/musicwhole_details" component={SheetMusicWholeDetails}></Route>
                                    <Route path="/views/sheetmusic/videodiff" component={VideoDiff}></Route>
                                    <Route path="/views/sheetmusic/download" component={Download}></Route>
                                    <Route path="/views/sheetmusic/download/musicscore" component={MusicScore}></Route>
                                    
                                    <Route path="/views/course/series" component={CourseSeries}></Route>
                                    <Route path="/views/course/lesson" component={lessonManage}></Route>
                                    <Route path="/views/course/lesson_details" component={courseLessonDetails}></Route>
                                    <Route path="/views/course/part" component={partManage}></Route>
                                    <Route path="/views/course/part_details" component={coursePartDetails}></Route>
                                    <Route path="/views/course/point" component={pointManage}></Route>
                                    <Route path="/views/course/point_details" component={coursePointDetails}></Route>
                                    {/* <Route path="/views/usermanage" component={UserManage}></Route> */}
                                    <Route path="/views/song/series" component={SongSeries}></Route>
                                    <Route path="/views/song/group" component={SongGroup}></Route>
                                    <Route path="/views/song/group_details" component={SongGroupDetails}></Route>
                                    <Route path="/views/song/list" component={SongList}></Route>
                                    <Route path="/views/song/song_details" component={SongDetail}></Route>
                                    <Route path="/views/song/point" component={SongPointList}></Route>
                                    <Route path="/views/song/point_details" component={SongPointDetail}></Route>
                                    <Route path="/views/video/list" component={VideoManage}></Route>
                                    <Route path="/views/video/details" component={VideoDetails}></Route>
                                    <Route path="/views/audio/list" component={AudioManage}></Route>
                                    <Route path="/views/audio/tool" component={AudioTool}></Route>
                                    <Route path="/views/member/list" component={AudioManage}></Route>
                                    <Route path="/views/member/tool" component={MemberTool}></Route>
                                    <Route path="/views/push/list" component={MessageList}></Route>
                                    <Route path="/views/push/message" component={MessagePush}></Route>
                                    <Route path="/views/audio/details" component={AudioDetails}></Route>
                                    <Route path="/views/preview/musicScore" component={PreviewMusicScore}></Route>
                                    <Route exact path="/views" render={() => <Redirect to="/views/home"></Redirect>} />
                                </Suspenses>
                            </Switch>
                        </Views>
                    </Route>
                    {isLogin ? (
                        <Route exact path="/" render={() => <Redirect to="/views"></Redirect>} />
                    ) : (
                        <Route exact render={() => <Redirect to="/login"></Redirect>} />
                    )}
                </Suspenses>
            </Switch>
        </Router>
    )
}

export default Routers
